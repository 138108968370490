
import { Component, Mixins } from 'vue-property-decorator';
import CustomModal from '@/component/custom-modal.vue';
import RegionSelect from '@/view/repository/region-select.vue';
import { area_list } from '@/view/repository/region';
import { queryRegionAndPaginate, addRegion, updateRegion, deleteRegion } from '@/api/repository/region';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate } from '@/mixin/validator';
import Test from '@/view/repository/test.vue';

const cloneDeep = require('lodash/cloneDeep');

@Component({
  components: {
    CustomModal,
    RegionSelect,
    Test,
  },
})
export default class Region extends Mixins(Validate) {
  form_item_layout: any = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };
  form: any = {
    area_name: '',
  };
  validator_list: any = [
    {
      field: 'area_name',
      message: '请填写区域名称',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  is_loading: boolean = false;
  table_list: any = {};
  area_list: any = {};
  modal: any = {
    title: '新增区域',
    visible: false,
    is_loading: false,
    is_edit: false,
    area_id: 0,
  };
  tips_modal: any = {
    title: '为保障APP端正常展示，需满足以下条件才能成功停用该区域',
    visible: false,
    content: [],
    is_loading: false,
    area_id: 0,
  };
  fail_modal: any = {
    title: '抱歉，因以下原因区域删除失败',
    visible: false,
    content: [],
  };
  next_page: number = 1;

  paginationOption(data: RemotePagination<any>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current_page: data.current || 1,
    };
  }

  // 校验地区是否有选择
  validateArea() {
    for (const key in this.area_list) {
      const item = this.area_list[key];
      if (item.indeterminate || item.checked) {
        return true;
      }
    }
  }

  // 通过遍历 area_list 生成最终发送给后端的数据
  getSendData(area_list: any) {
    const send_data: any = {
      region_area: [],
      region_area_id: [],
    };
    let _data: any = [];
    for (const key in area_list) {
      const area_item = area_list[key];
      const _checked_list = area_item.checked_list;
      if (_checked_list.size <= 0 || (_checked_list.size > 0 && _checked_list.size < area_item.childs.length)) {
        // 如果没有省全选或者有部分全选的省，就需要把选中的城市拼接上省份名称
        area_item.childs.map((item: any) => {
          if (!_checked_list.has(item.id)) {
            // 所有选中的城市
            const city = [...item.checked_list.values()];
            // 拼接选中城市的省份
            const checked_city = city.map((city_item: any) => {
              return {
                id: city_item.id,
                ext_name: `${item.ext_name}${city_item.ext_name}`,
              };
            });
            _data = [..._data, ...checked_city];
          }
        });
        _data = [..._data, ...area_item.checked_list.values()];
      } else if (_checked_list.size === area_item.childs.length) {
        // 所有省全选，就只传省的名称
        _data = [..._data, ...area_item.checked_list.values()];
      }
    }
    send_data.region_area = _data.map((item: any) => {
      return item.ext_name;
    });
    send_data.region_area_id = _data.map((item: any) => {
      return item.id;
    });
    return send_data;
  }

  // 将表格的数据转换成模态框中的数据
  transformTableData(table_data: AreaTableData) {
    const area_id = table_data.include_area_id.split(',');
    const _area_list = this.area_list;
    for (const key in _area_list) {
      const area_item = _area_list[key];
      const _checked_list = area_item.checked_list;
      area_item.childs.map((item: any) => {
        if (area_id.indexOf(item.id) > -1) {
          // 有全选的省
          _checked_list.set(item.id, item);
        } else {
          const _child_checked_list = item.checked_list;
          item.childs.map((child_item: any) => {
            if (area_id.indexOf(child_item.id) > -1) {
              _child_checked_list.set(child_item.id, child_item);
            }
            this.$nextTick(() => {
              item.checked_list = null;
              item.checked_list = new Map(_child_checked_list);
            });
          });
        }
        this.$nextTick(() => {
          area_item.checked_list = null;
          area_item.checked_list = new Map(_checked_list);
          this.updateIndeterminate(area_item, _checked_list);
        });
      });
    }
    return _area_list;
  }

  // 更新区域的 indeterminate 状态
  updateIndeterminate(area_item: any, _checked_list: any) {
    // 需要等到下一次 DOM 更新的时候才能拿到最新的 area_item.children_indeterminate 值
    this.$nextTick(() => {
      area_item.indeterminate =
        (!!_checked_list.size && _checked_list.size < area_item.childs.length) ||
        (!!area_item.children_indeterminate.size && area_item.children_indeterminate.size <= area_item.childs.length);
    });
  }

  // 模态框隐藏时数据还原
  handleModalHidden() {
    this.modal.visible = false;
    this.modal.is_loading = false;
    this.form.area_name = '';
    this.$nextTick(() => {
      this.resetValidatorStatus();
    });
    // 必须每次都深度克隆一个 area_list，以保证得到的是一个全新的，不带上次数据的对象
    // 确保垃圾回收器及时回收旧对象
    this.area_list = null;
    this.area_list = cloneDeep(area_list);
  }

  // 区域发生变化时
  handleAreaChange(area_item: any, e: any) {
    Object.assign(area_item, {
      indeterminate: false,
      checked: e.target.checked,
    });
    if (area_item.checked) {
      area_item.childs.forEach((item: any) => {
        area_item.checked = true;
        area_item.checked_list.set(item.id, item);
      });
    } else {
      area_item.checked_list.clear();
    }
  }

  // 省份选择发生变化时
  handleProvinceRegionChange(area_item: any, region: any, region_checkbox: any) {
    if (!area_item) {
      return;
    }
    const _checked_list = area_item.checked_list;
    if (region_checkbox.is_checked) {
      _checked_list.set(region.id, region);
    } else {
      _checked_list.delete(region.id);
    }
    region = region_checkbox.data;
    // Vue 不能监听到 Map 对象的变化。所以需要重新改变对象的地址
    area_item.checked_list = null;
    area_item.checked_list = new Map(_checked_list);
    this.updateIndeterminate(area_item, _checked_list);
    area_item.checked = _checked_list.size === area_item.childs.length;
  }

  handleTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchRegionData(this.next_page);
  }

  // 确定添加/编辑
  async handleModalOk() {
    if (!this.validateCommit()) {
      return;
    }
    if (!this.validateArea()) {
      this.$message.error('请选择包含地区');
      return;
    }
    const { region_area, region_area_id } = this.getSendData(this.area_list);
    const _region_area = region_area.join(',');
    const _region_area_id = region_area_id.join(',');
    this.modal.is_loading = true;
    let res: any = {};

    if (this.modal.is_edit) {
      // 编辑区域
      const id = this.modal.area_id;
      res = await updateRegion(id, this.form.area_name, _region_area, _region_area_id);
    } else {
      res = await addRegion(this.form.area_name, _region_area, _region_area_id);
    }
    if (res.status !== 200) {
      this.modal.is_loading = false;
      this.$message.error(res.message);
      return;
    }
    this.modal.is_loading = false;
    this.handleModalHidden();
    const success_msg = this.modal.is_edit ? '编辑成功' : '添加成功';
    this.$message.success(success_msg);
    this.fetchRegionData(this.next_page);
  }

  // 确定停用
  async handleTipsModalOk() {
    this.tips_modal.is_loading = true;
    const res: any = await deleteRegion(this.tips_modal.area_id);
    this.tips_modal.is_loading = false;
    this.tips_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.content = [res.message];
      this.fail_modal.visible = true;
      return;
    }
    this.$message.success('删除成功');
    this.fetchRegionData(this.next_page);
  }

  handleArrowChange(region: any) {
    for (const key in this.area_list) {
      const area_item = this.area_list[key];
      area_item.childs.map((item: any) => {
        if (item !== region) {
          item.is_show_popup = false;
        }
      });
    }
  }

  onAddArea() {
    this.modal.visible = true;
    this.modal.is_edit = false;
  }

  onEdit(record: any) {
    this.modal.title = '编辑区域';
    this.modal.is_edit = true;
    this.modal.area_id = record.id;
    this.form.area_name = record.name;
    this.area_list = this.transformTableData(record);
    this.modal.visible = true;
    const area_id_arr = record.include_area_id.split(',').map(Number);
    for (const prop in this.area_list) {
      const arr = this.area_list[prop].childs.map((item: any) => item.id).map(Number);
      const diff_id = arr.filter((item: any) => {
        return area_id_arr.indexOf(item) === -1;
      });
      if (diff_id.length === 0) {
        this.area_list[prop].checked = true;
      }
    }
  }

  onDelete(record: any) {
    this.tips_modal.visible = true;
    this.tips_modal.content = ['没有仓库使用该区域'];
    this.tips_modal.area_id = record.id;
  }

  @changeLoading(['is_loading'])
  async fetchRegionData(current: number = 1) {
    // true 表示需要分页
    this.table_list = await queryRegionAndPaginate(current, true);
  }

  async mounted() {
    this.area_list = cloneDeep(area_list);
    this.fetchRegionData();
    // this.modal.visible = true;
  }
}
