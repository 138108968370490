import { render, staticRenderFns } from "./management.vue?vue&type=template&id=65d5aee8&scoped=true"
import script from "./management.vue?vue&type=script&lang=ts"
export * from "./management.vue?vue&type=script&lang=ts"
import style0 from "./management.vue?vue&type=style&index=0&id=65d5aee8&prod&lang=stylus"
import style1 from "./management.vue?vue&type=style&index=1&id=65d5aee8&prod&lang=stylus&module=true"
import style2 from "./management.vue?vue&type=style&index=2&id=65d5aee8&prod&lang=stylus&scoped=true"
import style3 from "./management.vue?vue&type=style&index=3&id=65d5aee8&prod&lang=less"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "65d5aee8",
  null
  
)

export default component.exports