
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class RegionSelect extends Vue {
  @Prop() data!: any; // 当前省/直辖市
  @Prop() regionList!: any; // 当前省/直辖市所在的区域
  @Prop({ type: Boolean, default: true }) arrow!: boolean;
  value: any = this.data;
  is_children_checked_all: boolean = false; // 当前省/直辖市是否选中
  region_list_left: any = [];
  region_list_right: any = [];

  get indeterminate(): boolean {
    const _indeterminate = this.value.indeterminate;
    const _children_indeterminate = this.regionList.children_indeterminate;
    if (_indeterminate) {
      _children_indeterminate.set(this.value.id, _indeterminate);
    } else {
      _children_indeterminate.delete(this.value.id);
    }
    return _indeterminate;
  }

  get is_show_popup(): boolean {
    return this.value.is_show_popup;
  }

  @Watch('regionList.checked')
  onCheckedAllChange(checked: any) {
    this.is_children_checked_all = checked;
  }

  @Watch('regionList.checked_list')
  onCheckedListChange(checked_list: any) {
    this.is_children_checked_all = checked_list.has(this.value.id);
  }

  @Watch('value.checked_list')
  onValueCheckedListChange(checked_list: any) {
    this.value.childs.forEach((item: any) => {
      item.checked = checked_list.has(item.id);
    });
    this.updateIndeterminate(checked_list);
  }

  @Watch('is_children_checked_all')
  onCityCheckedAll(checked_all: boolean) {
    // 改变城市的选中状态
    if (checked_all) {
      const _checked_list = this.value.checked_list;
      this.value.childs.forEach((item: any) => {
        item.checked = true;
        _checked_list.set(item.id, item);
      });
      this.value.checked_list = null;
      this.value.checked_list = new Map(_checked_list);
    } else if (!checked_all && !this.indeterminate) {
      this.value.childs.forEach((item: any) => {
        item.checked = false;
        this.value.checked_list.clear();
      });
    }
  }

  sliceList() {
    this.value.childs = this.value.childs.map((item: any) => {
      return {
        ...item,
        checked: false,
      };
    });
    const length = this.value.childs.length;
    const _length = length % 2 === 1 ? length / 2 + 1 : length / 2;
    this.region_list_left = this.value.childs.slice(0, _length);
    this.region_list_right = this.value.childs.slice(_length);
  }

  updateIndeterminate(_checked_list: any) {
    this.value.indeterminate =
      !!_checked_list.size && _checked_list.size < this.value.childs.length;
  }

  // 省份复选框改变
  handleOuterChange(region: any, e: any) {
    this.is_children_checked_all = e.target.checked;
    const _checked_list = this.value.checked_list;
    if (this.is_children_checked_all) {
      this.value.childs.forEach((item: any) => {
        _checked_list.set(item.id, item);
      });
    } else {
      _checked_list.clear();
    }
    this.updateIndeterminate(_checked_list);
    this.$emit('province-change', {
      is_checked: this.is_children_checked_all,
      data: this.value,
    });
  }

  // 城市复选框改变
  handleChildrenChange(region: any, e: any) {
    const _checked_list = this.value.checked_list;
    if (e.target.checked) {
      _checked_list.set(region.id, region);
    } else {
      _checked_list.delete(region.id);
    }
    // 由于 Vue 监听不到 Map 对象的变化，需要重赋值
    this.value.checked_list = null;
    this.value.checked_list = new Map(_checked_list);
    this.updateIndeterminate(_checked_list);
    this.is_children_checked_all = _checked_list.size === this.value.childs.length;
    // 城市选中状态改变需要更新区域的变化
    this.$emit('province-change', {
      is_checked: this.is_children_checked_all,
      data: this.value,
    });
  }

  onShowPopup() {
    this.value.is_show_popup = !this.value.is_show_popup;
    this.$emit('arrowChange', this.value);
  }

  async mounted() {
    this.sliceList();
  }
}
