
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

const _ = {
  deep_clone: require('lodash/cloneDeep'),
};

interface AreaParmas {
  checked?: boolean;
  is_not_checked_all?: boolean;
  checked_list?: any;
  children_indeterminate?: any;
  childs?: AreaParmas[];
  id: number;
  indeterminate?: boolean;
  name?: string;
  deep?: number;
  ext_name?: string;
  is_show_arrow?: boolean;
  is_show_popup?: boolean;
  pid: number;
  pid_list?: string;
  visible?: boolean;
}

@Component
export default class RegionSelect extends Vue {
  @Prop() data!: any; // 当前省/直辖市

  data_list: AreaParmas[] = [];

  // 是否展示对应省份下的城市
  onShowCity(province: AreaParmas) {
    if (province.visible) {
      province.visible = !province.visible;
      this.deepList(province.childs || [], 'visible', false);
      return;
    }
    this.addLabel(['visible']);
    province.visible = !province.visible;
  }

  // 是否展示对应城市下的地区
  onShowRegion(city: AreaParmas, province: AreaParmas) {
    if (city.visible) return (city.visible = !city.visible);
    this.deepList(province?.childs || [], 'visible', false);
    city.visible = !city.visible;
  }

  // 点击多选框
  onCheckboxChange(list: AreaParmas, p_list: AreaParmas) {
    list.checked = !list.checked;
    this.deepList(list?.childs || [], 'checked', list.checked);
    list.is_not_checked_all = list.checked ? false : true;
    this.changeCheckboxStyle(list, list.id);
  }

  // 往地区数组中每层添加一个属性
  deepList(list: AreaParmas[], label: string, value: any) {
    list.forEach((item: AreaParmas) => {
      this.$set(item, label, value);
      if (item.childs && item.childs.length > 0) {
        this.deepList(item.childs, label, value);
      }
    });
  }

  // 层级联动
  changeCheckboxStyle(list: AreaParmas, key: number) {
    const arr: number[] = [];
    const searchIdList = (list: AreaParmas[], id: number = key) => {
      list.forEach((item: AreaParmas) => {
        if (item.id == id) {
          arr.push(+item.id);
          if (item.pid) searchIdList(this.data_list, item.pid);
        }
        if (item.childs && item.childs.length > 0) searchIdList(item.childs, id);
      });
    };
    searchIdList(this.data_list);

    const setIsNotCheckedAll = (list: AreaParmas[], id: number) => {
      list.forEach((item: AreaParmas) => {
        if (item.id == id) {
          if (item?.childs) {
            if (
              item?.childs.filter((val: AreaParmas) => val.checked == false)?.length == item?.childs.length ||
              item?.childs.filter((val: AreaParmas) => val.checked == true)?.length == item?.childs.length
            ) {
              item.is_not_checked_all = false;
            } else item.is_not_checked_all = true;
          }
        }
        if (item.childs && item.childs.length > 0) setIsNotCheckedAll(item.childs, id);
      });
    };

    const setCheckAllList = (list: AreaParmas[], id: number) => {
      list.forEach((item: AreaParmas) => {
        if (item.id == id) {
          if (item.childs) {
            if (
              item?.childs.filter((val: AreaParmas) => val.is_not_checked_all == false)?.length == item?.childs.length &&
              item?.childs.filter((val: AreaParmas) => val.checked == false)?.length == item?.childs.length
            ) {
              item.is_not_checked_all = false;
            } else if (
              item?.childs.filter((val: AreaParmas) => val.is_not_checked_all == false)?.length == item?.childs.length &&
              item?.childs.filter((val: AreaParmas) => val.checked == true)?.length == item?.childs.length
            ) {
              item.is_not_checked_all = false;
              item.checked = true;
            } else item.is_not_checked_all = true;
            if (item?.childs.filter((val: AreaParmas) => val.checked == false)?.length == item?.childs.length) {
              item.checked = false;
            } else if (item?.childs.filter((val: AreaParmas) => val.checked == true)?.length == item?.childs.length) {
              item.checked = true;
            }
          } else {
            item.is_not_checked_all = item.checked;
            const setList = (list: AreaParmas[], id: number) => {
              list.forEach((val: AreaParmas) => {
                if (val.id == id && val.childs) {
                  if (val.childs.filter((e: AreaParmas) => e.checked == true).length == val.childs.length) {
                    val.childs.forEach((e: AreaParmas) => (e.is_not_checked_all = false));
                  } else item.is_not_checked_all = item.checked;
                }
                if (val.childs && val.childs.length > 0) setList(val.childs, id);
              });
            };
            setList(this.data_list, item.pid);
          }
          console.log(item);
        } else {
          if (item.childs && item.childs.length > 0) setCheckAllList(item.childs, id);
        }
      });
    };

    arr.forEach((element: number) => {
      setIsNotCheckedAll(this.data_list, element);
    });
    // arr.shift();
    arr.forEach((element: number) => {
      setCheckAllList(this.data_list, element);
    });
  }

  getList() {
    for (const key in this.data) {
      this.data[key].pid = 0;
      this.data_list.push(this.data[key]);
    }
  }

  // 添加字段
  addLabel(label_arr: string[]) {
    label_arr.forEach((item: string) => {
      this.data_list.forEach((element: AreaParmas) => this.deepList(element.childs || [], item, false));
    });
  }
  async mounted() {
    this.getList();
    this.addLabel(['visible', 'checked', 'is_not_checked_all']);
  }
}
