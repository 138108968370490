
import AMapLoader from '@amap/amap-jsapi-loader';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import CustomModal from '@/component/custom-modal.vue';
import storage from 'baimahu/storage';
import { getValidatorMap, Validate } from '@/mixin/validator';
import Pda from '@/component/pda.vue'
import {
  queryRepoAndPaginate,
  addRepo,
  updateRepo,
  stopRepo,
  addPostage,
  updatePostage,
  deletePostage,
  people,
  getRepertory
} from '@/api/repository/repo';
import { queryRegionList } from '@/api/repository/region';
import { changeLoading } from '@/util/decorators';
import { setRegion } from '@/view/repository/region';
import { request as axios } from '@/util/request';
const repo_columns = [
  {
    title: '门店ID',
    dataIndex: 'repo_id_text',
    key: 'repo_id',
    width: '10%',
  },
  {
    title: '门店名称',
    dataIndex: 'repo_name',
    key: 'repo_name',
    width: '14%',
  },
  {
    title: '门店地址',
    dataIndex: 'repo_address',
    key: 'repo_address',
    width: '18%',
  },
  {
    title: '门店号码',
    dataIndex: 'repo_phone',
    key: 'repo_phone',
    width: '14%',
  },
  {
    title: '通用配送费(元)',
    dataIndex: 'repo_money',
    key: 'repo_money',
    width: '14%',
  },
  {
    title: '发货范围(公里)',
    dataIndex: 'repo_fanwei',
    key: 'repo_fanwei',
    width: '14%',
  },
  {
    title: '创建时间',
    dataIndex: 'common_arrive_time_text',
    key: 'common_arrive_time',
    width: '14%',
    scopedSlots: {
      customRender: 'common_arrive_time',
    },
  },
  {
    title: '状态',
    dataIndex: 'status_text',
    key: 'status',
    width: '14%',
  },
  {
    title: '操作',
    dataIndex: 'repo_operation',
    key: 'repo_operation',
    width: '14%',
    scopedSlots: {
      customRender: 'repo_operation',
    },
  },
];

const area_columns = [
  {
    title: '',
    dataIndex: 'space',
    key: 'space',
    width: '10%',
  },
  {
    title: '区域ID',
    dataIndex: 'id_text',
    key: 'id',
    width: '14%',
  },
  {
    title: '区域名称',
    dataIndex: 'area_name',
    key: 'area_name',
    width: '18%',
  },
  {
    title: '区域运费',
    dataIndex: 'area_postage_text',
    key: 'area_postage',
    width: '14%',
  },
  {
    title: '区域送达时间',
    dataIndex: 'area_arrive_time_text',
    key: 'area_arrive_time',
    width: '14%',
  },
  {
    title: '',
    dataIndex: 'empty',
    key: 'empty',
    width: '14%',
  },
  {
    title: '操作',
    dataIndex: 'area_operation',
    key: 'area_operation',
    width: '14%',
    scopedSlots: {
      customRender: 'area_operation',
    },
  },
];
const SEARCH_TYPE=[
    {
    id: 0,
    value: '门店ID',
  },
  {
    id: 1,
    value: '门店名称',
  },
]

const _ = {
  map: require('lodash/map'),
  each: require('lodash/forEach'),
};

@Component({
  name:'RepositoryManagement',
  components: {
    CustomModal,
    Pda
  },
})
export default class RepositoryManagement extends Mixins(Validate) {
  form_item_layout: any = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  form_item_layout_repertory: any = {
    labelCol: { span: 2},
    wrapperCol: { span: 10 },
  };
  form: any = {
    search_type:0,
    repo_name: '', // 门店名称
    repo_address: '', // 门店地址
    address:'',
    repo_phone: '',//门店号码
    phone:'',
    repo_status:0,//门店状态
    status:0,
    repo_people:'',//门店负责人
    repo_money: 0, // 通用邮费
    common_arrive_time: 7, // 通用到达时间
  };
  area_validator_form: any = {
    area_name: '', // 区域名称
    area_postage: 0, // 区域邮费
    area_arrive_time: 7, // 区域到达时间
  };
  validator_list: any = [
    {
      field: 'repo_name',
      message: '请填写门店名称',
    },
    {
      field: 'repo_address',
      message: '请填写门店地址',
    },
    {
      field:'repo_phone',
      message: '请填写11位手机号码',
      validate: (value: string): boolean => {
        if (value === '' || !(/^1[3456789]\d{9}$/.test(value))) {
          return !!value;
        }else{
          return true;
        }
        
      },
    },
    {
      field: 'repo_money',
      message: '请填写通用运费',
      /* validate: (value: number): boolean => {
        if (value === 0) {
          return true;
        }
        return !!value;
      }, */
    },
    {
      field: 'fhfw',
      message: '请填写发货范围',
      /* validate: (value: number): boolean => {
        if (value === 0) {
          return true;
        }
        return !!value;
      }, */
    },
  ];
  area_validator_list: any = [
    {
      field: 'area_name',
      message: '请选择区域',
    },
    {
      field: 'area_postage',
      message: '请填写区域运费或选择包邮',
      validate: (value: number): boolean => {
        if (value === 0) {
          return true;
        }
        return !!value;
      },
    },
    {
      field: 'area_arrive_time',
      message: '请填写区域送达时间',
      validate: (value: number): boolean => {
        if (value === 0) {
          return true;
        }
        return !!value;
      },
    },
  ];
  tider_table: any=[];//负责人表格数据
  people_dis: boolean=false;//负责人弹窗显隐
  addpeople_dis: boolean=false;//新增负责人弹窗显隐
  addName: string='';//新增负责人姓名
  addPhone: string='';//新增负责人电话
  gaode_dis: boolean=false;//高德地图弹窗
  //搜索条件
  get searchType() {
    return SEARCH_TYPE;
  }
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  area_validator_map: { [field: string]: Validator } = getValidatorMap(this.area_validator_list);
  area_field_watch_list: any = [];

  is_loading: boolean = false;

  repo_columns: any = repo_columns;
  repo_table_data: any = {}; // 门店表格的数据
  area_columns: any = area_columns;

  // 门店库存弹窗显隐
  repertory_modal: boolean = false
  // 门店库存table数据
  repertory_table: any ={}
  // 门店分页
  repertory_current: number = 1
  // u8编码
  codeType: string = 'u8编码'
  // Id
  wareId: string = ''
  // 产品名称
  wareName: string = ''
  // 产品规格
  wareSpec: string = ''
  is_repertory_loading: boolean = false

  repo_modal: SimpleModal = {
    title: '新增门店',
    visible: false,
    is_loading: false,
    is_edit: false,
  };
  area_modal: SimpleModal = {
    title: '新增区域运费',
    visible: false,
    is_loading: false,
    is_edit: false,
    subtitle: '客户下单优先匹配区域运费，如无匹配区域则按照通用运费结算',
  };
  tips_modal: SimpleModal = {
    title: '',
    visible: false,
    is_loading: false,
    content: [],
    repo_id: 0,
  };
  fail_modal: SimpleModal = {
    title: '',
    visible: false,
    content: [],
  };
  delete_postage_modal: SimpleModal = {
    title: '确认删除？',
    visible: false,
    is_loading: false,
    area_id: 0,
  };

  POSTAGE_TYPE = {
    free: 1, // 包邮
    cost: 2,
  };

  province_list: any = []; // 省
  city_list: any = []; // 市
  district_list: any = []; // 区
  area_list: any = []; // 区域列表

  repo_form_data: RepoData = {
    repo_id: 0,
    repo_name: '',
    repo_address: {
      province_id: undefined,
      province: '',
      city_id: undefined,
      city: '',
      district_id: undefined,
      district: '',
      details: '',
    },
    repo_phone:'',
    repo_fanwei:0,
    common_postage_type: 1,
    common_postage: '',
    common_arrive_time: 7,
    repo_bm: '',
    repo_my:'',
    repo_money:0
  };
  area_form_data: AreaData = {
    id: 0,
    repo_id: 0,
    region_id: undefined,
    area_name: '',
    area_postage_type: 1,
    area_postage: '',
    area_arrive_time: 7,
  };
  next_page: number = 1;
  pdaData: any = {
    type: 2,
    id: ""
  }
  pdaVisible: boolean = false
  get head_address(): string {
    const address = this.repo_form_data.repo_address;
    return (address.province as string) + (address.city as string) + address.district;
  }
  // 门店弹窗初始
  @changeLoading(['is_repertory_loading'])
  async repertory(record: any){
    this.warehouse_id=record.repo_id
    const body={
      warehouse_id:record.repo_id,
      current:this.repertory_current
    }
    const repertory_data=await getRepertory(body)
    if(repertory_data.status !== 200){
      this.$message.error("请求失败")
    }else{
      this.repertory_modal = true
      this.repertory_table=repertory_data.data; 
    }
  }
  pda(value: any){
    this.pdaData.id = value.repo_id
    this.pdaVisible = true
  }
  // 编码改变
  codeChange(value: any){
    this.codeType = value
  }
  // 搜索
  @changeLoading(['is_repertory_loading'])
  async repertory_find(){
    this.repertory_current = 1
    const body = this.getRepertoryBody()
    const repertory_data=await getRepertory(body)
    if(repertory_data.status !== 200){
      this.$message.error("请求失败")
    }else{
      this.repertory_table=repertory_data.data; 
    }
  }
  // 请求参数
  getRepertoryBody(){
    const body: any={
      warehouse_id:this.warehouse_id,
      current:this.repertory_current,
      u8_code:this.codeType ==='u8编码'? this.wareId :'',
      pd_id:this.codeType ==='产品编号' ? this.wareId :'',
      name:this.wareName,
      spec:this.wareSpec
    }
    Object.keys(body).forEach((item)=>{
      if(body[item] === '') delete body[item]
    })
    return body
  }
  // 清除搜索条件
  repertory_clear(){
    this.wareId = ''
    this.wareName = ''
    this.wareSpec = ''
  }
  // 弹窗关闭
  repertory_modal_cancel(){
    this.repertory_clear()
    this.repertory_modal = false
    this.repertory_current = 1
  }
  // 分页请求
  @changeLoading(['is_repertory_loading'])
  async handleRepertoryChange( pagination: any){
    const current = this.repertory_current
    this.repertory_current = pagination.current
    const body = this.getRepertoryBody()
    const repertory_data=await getRepertory(body)
    if(repertory_data.status !== 200){
      this.$message.error("请求失败")
      this.repertory_current = current
    }else{
      this.repertory_table=repertory_data.data; 
    }
  }
  repertoryPaginationOption(data: RemotePagination<any>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current_page: data.current || 1,
      current:this.repertory_current
    };
  }
  @Watch('repo_form_data', { deep: true })
  onRepoFormDataChange(repo_form_data: RepoData) {
    this.form.repo_name = repo_form_data.repo_name;
    this.form.common_arrive_time = repo_form_data.common_arrive_time;
    this.form.repo_address=repo_form_data.repo_address.details
    this.form.repo_phone=repo_form_data.repo_phone
    // 需要同时监听省市区和详细地址，只要有一个没有填就不通过
    /* if (
      repo_form_data.repo_address.province &&
      repo_form_data.repo_address.city &&
      repo_form_data.repo_address.district &&
      repo_form_data.repo_address.details
    ) {
      this.form.repo_address = this.head_address + repo_form_data.repo_address.details;
    } else {
      this.form.repo_address = '';
    }
    if (repo_form_data.common_postage_type === this.POSTAGE_TYPE.free) {
      this.repo_form_data.common_postage = ''; // 清空输入框的值
      this.validatorMap.common_postage.status = 'success';
      this.validatorMap.common_postage.help = '';
      this.form.common_postage = 0;
    } */
  }

  @Watch('area_form_data', { deep: true })
  onAreaFormDataChange(area_form_data: AreaData) {
    this.area_validator_form.area_name = area_form_data.area_name;
    this.area_validator_form.area_arrive_time = area_form_data.area_arrive_time;
    if (area_form_data.area_postage_type === this.POSTAGE_TYPE.free) {
      this.area_form_data.area_postage = ''; // 清空输入框的值
      this.area_validator_map.area_postage.status = 'success';
      this.area_validator_map.area_postage.help = '';
      this.area_validator_form.area_postage = 0;
    }
  }
  
  current: any
  paginationOption(data: RemotePagination<any>) {
    this.current=data.current || 1;
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current_page: data.current || 1,
    };
  }

  clearRepoModal() {
    this.repo_form_data = {
      repo_name: '',
      repo_address: {
        province_id: undefined,
        province: '',
        city_id: undefined,
        city: '',
        district_id: undefined,
        district: '',
        details: '',
      },
      common_postage_type: 1,
      common_postage: '',
      common_arrive_time: 7,
    };
    this.zuobiao = []
    this.$nextTick(() => {
      this.resetValidatorStatus();
    });
  }

  clearAreaPostageModal() {
    this.area_form_data = {
      id: 0,
      repo_id: 0,
      region_id: undefined,
      area_name: '',
      area_postage_type: 1,
      area_postage: '',
      area_arrive_time: 7,
    };
    this.$nextTick(() => {
      this.resetAreaValidatorStatus();
    });
  }

  getCityList(province_id: string) {
    this.province_list.map((item: any) => {
      if (item.id === province_id) {
        this.city_list = item.childs;
        this.district_list = [];
        this.repo_form_data.repo_address.province = item.ext_name;
        this.repo_form_data.repo_address.province_id = province_id;
        this.repo_form_data.repo_address.city_id = undefined;
        this.repo_form_data.repo_address.district_id = undefined;
      }
    });
  }

  getDistrictList(city_id: string) {
    this.city_list.map((item: any) => {
      if (item.id === city_id) {
        this.district_list = item.childs;
        this.repo_form_data.repo_address.city = item.ext_name;
        this.repo_form_data.repo_address.city_id = city_id;
        this.repo_form_data.repo_address.district_id = undefined;
      }
    });
  }

  getDistrictName(district_id: string) {
    this.district_list.map((item: any) => {
      if (item.id === district_id) {
        this.repo_form_data.repo_address.district = item.ext_name;
        this.repo_form_data.repo_address.district_id = district_id;
      }
    });
  }

  getPostageSendData() {
    const send_data = {
      warehouse_id: this.area_form_data.repo_id as number,
      region_id: this.area_form_data.region_id as number,
      region_name: this.area_form_data.area_name as string,
      freight_type: this.area_form_data.area_postage_type,
      freight:
        this.area_form_data.area_postage_type === 1 ? 0 : +this.area_form_data.area_postage * 100,
      delivery_time: this.area_form_data.area_arrive_time,
    };
    return send_data;
  }

  getRepoSendData() {
    const send_data = {
      name: this.repo_form_data.repo_name,
      /* province_id: +(this.repo_form_data.repo_address.province_id as string),
      city_id: +(this.repo_form_data.repo_address.city_id as string),
      district_id: +(this.repo_form_data.repo_address.district_id as string),
      head_address: this.head_address, */
      address: this.repo_form_data.repo_address.details,
      /* freight_type: this.repo_form_data.common_postage_type,
      delivery_time: this.repo_form_data.common_arrive_time, */
      knight_range:Number(this.repo_form_data.repo_fanwei)*1000,
      knight_freight:Number(this.repo_form_data.repo_money)*100,
      lon:this.thisPosition.lng,
      lat:this.thisPosition.lat,
      contact_mobile:this.repo_form_data.repo_phone,
      printer_key:this.repo_form_data.repo_my,
      printer_number: this.repo_form_data.repo_bm
    };
    return send_data;
  }

  validateArea(field: string) {
    const is_valid = this.area_validator_map[field].validate(this.area_validator_form[field]);
    this.area_validator_map[field].status = is_valid ? 'success' : 'error';
    this.area_validator_map[field].help = is_valid ? '' : this.area_validator_map[field].message;
    return is_valid;
  }

  // 校验区域运费
  validateCommitArea() {
    let is_valid: boolean = true;
    _.each(this.area_validator_map, (validator: Validator, field: string) => {
      if (!this.validateArea(field)) {
        is_valid = false;
      }
    });
    if (!is_valid) {
      this.$message.error('请填入必填项');
    }
    return is_valid;
  }

  resetAreaValidatorStatus() {
    _.each(this.area_validator_map, (validator: Validator) => {
      validator.help = '';
      validator.status = 'success';
    });
  }

  handleAddRepoCancel() {
    this.repo_modal.visible = false;
    this.clearRepoModal();
  }

  handleAddAreaCancel() {
    this.area_modal.visible = false;
    this.clearAreaPostageModal();
  }

  handleTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchRepoData(this.next_page);
  }

  handleProvinceChange(province_id: string) {
    this.getCityList(province_id);
  }

  handleCityChange(city_id: string) {
    this.getDistrictList(city_id);
  }

  handleDistrictChange(district_id: string) {
    this.getDistrictName(district_id);
  }

  // 通用运费输入框聚焦时
  handleCommonPostageFocus() {
    this.repo_form_data.common_postage_type = this.POSTAGE_TYPE.cost;
  }

  // 通用送达时间输入框失去焦点
  handleArriveTimeBlur(e: any) {
    this.repo_form_data.common_arrive_time = ~~e.target.value; // 取正整数
  }

  // 区域选择发生变化
  handleAreaNameChange(e: number) {
    this.area_list.map((item: any) => {
      if (item.id === e) {
        this.area_form_data.area_name = item.name;
        this.area_form_data.region_id = item.id;
      }
    });
  }

  // 区域运费输入框聚焦
  handleAreaPostageFocus() {
    this.area_form_data.area_postage_type = this.POSTAGE_TYPE.cost;
  }

  // 区域送达时间输入框失去焦点
  handleAreaArriveTimeBlur(e: any) {
    this.area_form_data.area_arrive_time = ~~e.target.value; // 取正整数
  }

  // 确认新增/编辑门店
  async handleAddRepoOk() {
    // 为了第一次切换邮费时不校验，选择在提交的时候才改变 common_postage 的值
    if (this.repo_form_data.common_postage_type === this.POSTAGE_TYPE.cost) {
      this.form.common_postage = this.repo_form_data.common_postage;
    }
    if(this.repo_form_data.repo_name==''){
      this.$message.error('其输入门店名称')
      return
    }else if(this.repo_form_data.repo_phone==''||!(/^1[3456789]\d{9}$/.test(String(this.repo_form_data.repo_phone)))){
      this.$message.error('请输入11位手机号')
      return
    }else if(this.repo_form_data.repo_address.details==''){
      this.$message.error('请输入地址')
      return
    }
    else if(this.repo_form_data.repo_money==undefined ||!(/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(String(this.repo_form_data.repo_money)))){
      this.$message.error('请输入最多两位小数的正整数通用配送费')
      return
    }else if(this.repo_form_data.repo_fanwei==undefined ||!(/^[1-9]\d*$/.test(String(this.repo_form_data.repo_fanwei)))){
      this.$message.error('请输入发货范围,为正整数')
      return
    }else if(this.thisPosition.lng==''||this.thisPosition.lat==''){
      this.$message.error('请选择坐标')
      return
    }
    /* if (!this.validateCommit()) {
      return;
    } */
    else{
      this.repo_modal.is_loading = true;
    if (this.repo_modal.is_edit) {
      const res: any = await updateRepo(
        this.repo_form_data.repo_id as number,
        this.getRepoSendData()
      );
      if (res.status !== 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success('编辑成功');
      }
    } else {
      const res: any = await addRepo(this.getRepoSendData());
      if (res.status !== 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success('添加成功');
      }
    }
    this.repo_modal.is_loading = false;
    this.repo_modal.visible = false;
    this.clearRepoModal();
    this.fetchRepoData(this.next_page);
    }
    
  }

  // 确定新增/编辑区域运费
  async handleAddAreaOk() {
    // 为了第一次切换邮费时不校验，选择在提交的时候才改变 area_postage 的值
    if (this.area_form_data.area_postage_type === this.POSTAGE_TYPE.cost) {
      this.area_validator_form.area_postage = this.area_form_data.area_postage;
    }
    if (!this.validateCommitArea()) {
      return;
    }
    this.area_modal.is_loading = true;
    if (this.area_modal.is_edit) {
      const res: any = await updatePostage(
        this.area_form_data.id as number,
        this.getPostageSendData()
      );
      if (res.status !== 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success('编辑成功');
      }
    } else {
      const res: any = await addPostage(this.getPostageSendData());
      if (res.status !== 200) {
        this.$message.error(res.message);
      } else {
        this.$message.success('添加成功');
      }
    }
    this.area_modal.is_loading = false;
    this.area_modal.visible = false;
    this.clearAreaPostageModal();
    this.fetchRepoData(this.next_page);
  }

  // 确定停用
  async handleStopConfirm() {
    this.tips_modal.is_loading = true;
    // true 表示停用门店, false 启用门店
    const res: any = await stopRepo(this.tips_modal.repo_id, true);
    this.tips_modal.is_loading = false;
    this.tips_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.title = '抱歉，因以下原因门店删除失败';
      this.fail_modal.content = [res.message];
      this.fail_modal.visible = true;
      return;
    }
    this.fetchRepoData(this.next_page);
  }

  // 启用
  async handleStartRepo() {
    const res: any = await stopRepo(this.tips_modal.repo_id, false);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.fetchRepoData(this.next_page);
  }

  // 确认删除门店区域运费
  async handleDeleteOk() {
    this.delete_postage_modal.is_loading = true;
    const res: any = await deletePostage(this.delete_postage_modal.area_id);
    if (res.status !== 200) {
      this.$message.error(res.message);
    } else {
      this.$message.success('删除成功');
    }
    this.delete_postage_modal.is_loading = false;
    this.delete_postage_modal.visible = false;
    this.fetchRepoData(this.next_page);
  }

  onAddRepo() {
    this.repo_modal.title = '新增门店';
    this.repo_modal.visible = true;
    this.repo_modal.is_edit = false;
  }
  //清空
  clear(){
    this.form.search_type=0;
    this.form.search_value='';
    this.form.address='';
    this.form.phone='';
    this.form.people='';
    this.form.status=0
    this.find()
  }

  onEditRepo(record: any) {
    console.log(record)
    this.zuobiao.push(record.lon)
    this.zuobiao.push(record.lat)
    this.repo_form_data.repo_name = record.repo_name;
    this.repo_form_data.repo_id = record.repo_id;
    this.repo_form_data.repo_address.details = record.details;
    this.repo_form_data.common_postage_type = record.common_postage_type;
    this.repo_form_data.common_postage = record.common_postage;
    this.repo_form_data.common_arrive_time = record.common_arrive_time;
    this.repo_form_data.repo_phone=record.repo_phone
    this.repo_form_data.repo_money=record.repo_money
    this.repo_form_data.repo_fanwei=record.repo_fanwei
    this.repo_form_data.repo_bm=record.printer_number
    this.repo_form_data.repo_my=record.printer_key
    this.thisPosition.lng=record.lon
    this.thisPosition.lat=record.lat
    this.getCityList(record.province_id);
    this.getDistrictList(record.city_id);
    this.getDistrictName(record.district_id);
    this.repo_modal.title = '编辑门店';
    this.repo_modal.is_edit = true;
    this.repo_modal.visible = true;
  }
  //负责人
  warehouse_id: string='';//门店id
  async people(record: any){
    this.warehouse_id=record.repo_id
    this.people_dis=true
    const body={
      warehouse_id:record.repo_id,
      current:this.current
    }
    this.tider_table=await people(body);
    console.log(this.tider_table)
  }
  //停用启用负责人
  async peoplecz(record: any){
    const res=await axios.put(`/boss/warehouse/staff/status/${record.id}`,{
      status:record.status==1?2:1
    })
    if(res.status!=200){
      this.$message.error('操作失败')
      return false
    }else{
      console.log(this.warehouse_id)
      const body={
      repo_id:this.warehouse_id,
      current:this.current
    }
      this.people(body)
    }
  }
  //删除负责人
  async deletefzr(record: any){
    const r=confirm("确认删除吗");
    if(r){
      const res=await axios.delete(`/boss/warehouse/staff/${record.id}`)
      if(res.status==200){
        this.$message.success('删除成功')
        const body={
          warehouse_id:this.warehouse_id,
          current:this.current
        }
    this.tider_table=await people(body);
      }else{
        this.$message.error((res as any).message)
      }
    }
  }
  //新增负责人弹窗
  addpeople(){
    this.addpeople_dis=true
  }
  //关闭新增负责人弹窗
  addpeopleClose(){
    this.addpeople_dis=false
  }

  //关闭新增负责人弹窗
  async addpeopleTrue(){
    if(this.addName==''||this.addPhone==''){
      this.$message.error('姓名跟电话号码都不能为空，请检查')
      return false
    }else if(!(/^1[3456789]\d{9}$/.test(this.addPhone))){
      this.$message.error('手机号格式有误，请检查')
      return false
    }
    else{
      const body={
        warehouse_id:this.warehouse_id,
        name:this.addName,
        mobile:this.addPhone
      }
      const res=await axios.post('/boss/warehouse/staff',body)
      if(res.status==200){
        this.$message.success('新增负责人成功')
        this.addpeople_dis=false
        const record={
          repo_id:this.warehouse_id
        }
        this.people(record)
      }else{
        this.$message.error((res as any).message)
        return false
      }
      
    }
    
  }

  onAddAreaPostage(record: any) {
    this.area_form_data.repo_id = record.repo_id;
    this.area_modal.title = '新增区域运费';
    this.area_modal.visible = true;
    this.area_modal.is_edit = false;
  }

  // 停用/启用门店
  onRepoStatusChange(record: any) {
    this.tips_modal.repo_id = record.repo_id;
    switch (record.status) {
      case 1:
        // 停用操作
        this.tips_modal.title = '为保障APP端正常展示，需满足以下条件才能成功停用该门店';
        this.tips_modal.content = ['没有商品使用该门店'];
        this.tips_modal.visible = true;
        break;
      case 2:
        // 启用操作
        this.handleStartRepo();
        break;
      default:
        break;
    }
  }

  // 编辑区域运费
  onEditArea(record: any) {
    this.area_form_data.id = record.id;
    this.area_form_data.repo_id = record.repo_id;
    this.area_form_data.region_id = record.region_id;
    this.area_form_data.area_name = record.area_name;
    this.area_form_data.area_postage_type = record.area_postage_type;
    this.area_form_data.area_postage = record.area_postage;
    this.area_form_data.area_arrive_time = record.area_arrive_time;
    this.area_modal.title = '编辑区域运费';
    this.area_modal.is_edit = true;
    this.area_modal.visible = true;
  }

  // 删除区域运费
  onDeleteArea(record: any) {
    this.delete_postage_modal.area_id = record.id;
    this.delete_postage_modal.visible = true;
  }

  @changeLoading(['is_loading'])
  async fetchRepoData(current: number = 1,body? : any) {
    this.repo_table_data = await queryRepoAndPaginate(current,body);
  }
  //搜索
  find(){
    const body: any={
      id:this.form.search_value,
      name:this.form.search_value,
      address:this.form.address,
      contact_mobile:this.form.phone,
      status:this.form.status,
      staff_name:this.form.people
    }
    if(this.form.people==''){
      delete body.staff_name
    }
    if(this.form.address==''){
      delete body.address
    }
    if(this.form.phone==''){
      delete body.contact_mobile
    }
    if(this.form.search_value==''){
      delete body.id
      delete body.name
    }
    if(this.form.search_type==1){
      delete body.id
    }else if(this.form.search_type==0){
      delete body.name
    }
    this.fetchRepoData(1,body)
  }

  async fetchAreaData() {
    this.area_list = await queryRegionList();
  }

  async created() {
    this.area_field_watch_list = _.map(
      this.area_validator_map,
      (validator: Validator, field: string) =>
        this.$watch(`area_validator_form.${field}`, () => {
          this.validateArea(field);
        })
    );
    //await this.initMap()
  }

  @changeLoading(['is_loading'])
  async mounted() {
    await setRegion(); // 设置 localStorage 中的 region_list
    await this.fetchRepoData();
    await this.fetchAreaData();
    this.province_list = storage.getItem('region_list');
    
  }

  beforeDestroy() {
    this.area_field_watch_list.forEach((unwatch: () => void) => {
      if (unwatch) {
        unwatch();
      }
    });
  }
  //地图控件
  map: any
  //输入提示
    autoOptions: any = {
        input: "tishikuang"
    };
    dialogVisible=false
    positionVal=""
    marker= null
    geocoder= null // 地理编码与逆地理编码类，用于地址描述与坐标之间的转换
    startAutoComplete: any
    startPlaceSearch: any
    positionPicker= null
    startSeacrh= [] as any[]
    stratInfo= {}
    thisPosition= {
      location: "",
      lng: "",
      lat: "",
    }
    mapinitCode= 0 //地理编码:1;地理逆编码:2
    MapAdress= ""
    insureAdress= ""
    insureAdress2= ""
    
    async chooseGaode(){
      this.gaode_dis=true
      await this.initMap()
    }
  //地图
  zuobiao: any=[]
  amap: any = null
  async initMap(){
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this
        AMapLoader.load({
            "key": "44c3cdd1674c99a6e1a440f495c1367f", // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ['AMap.ToolBar', 'AMap.Scale', 'AMap.HawkEye', 'AMap.MapType',
                        'AMap.Geolocation', 'AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Geocoder','AMap.MouseTool'],
            "AMapUI": { // 是否加载 AMapUI，缺省不加载
            "version": '1.1', // AMapUI 缺省 1.1
            "plugins": [], // 需要加载的 AMapUI ui插件
             },
            "Loca": { // 是否加载 Loca， 缺省不加载
            "version": '2.0' // Loca 版本，缺省 1.3.2
            },
        }).then((AMap) => {
          that.amap = AMap
          that.map = new AMap.Map('container',{
              //center: [117.928025,36.850767],
              zoom: 15,
              viewMode: '3D',
              resizeEnable: true
          });
          //给地图新增缩放条，和 地图左下角的海里控件
          this.map.addControl(new AMap.Scale())
          this.map.addControl(new AMap.ToolBar())
          that.geocoder = new AMap.Geocoder();
          AMap.plugin(["AMap.PlaceSearch", "AMap.Autocomplete"], function () {
              const autoOptions1 = { 
                input: "tipinput", 
                city: "zibo" 
              };
              that.startAutoComplete = new AMap.Autocomplete(autoOptions1);
              that.startPlaceSearch = new AMap.PlaceSearch({
                map: that.map,
              });
               
              that.startAutoComplete.on("select", that.handleStartSelect); //注册监听，当选中某条记录时会触发
            });
            if(this.thisPosition.lng!=''&&this.thisPosition.lat!=''){
              this.marker = new AMap.Marker({
              position: new AMap.LngLat(this.thisPosition.lng,this.thisPosition.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            that.map.add(this.marker); //添加到地图
            }
            this.map.on('dragend', (event: any)=>{
              console.log(this.map,event)
            });
            //this.map.on('dragend', console.log(this.map));
            this.map.on("click",(event: any)=>{
              if(this.marker!=null){
                that.map.remove(this.marker)
              }
              this.marker = new AMap.Marker({
              position: new AMap.LngLat(event.lnglat.lng,event.lnglat.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
              that.map.add(this.marker); //添加到地图
              this.thisPosition.lng=event.lnglat.lng
              this.thisPosition.lat=event.lnglat.lat
              this.zuobiao = []
              this.zuobiao.push(event.lnglat.lng)
              this.zuobiao.push(event.lnglat.lat)
            })
            
            
            that.map.setZoom(14);//设置缩放大小
            //reslove();
          }).catch((e) => {
            console.log(e, "高德地图加载失败");
            //reject(e);
          });    
	}
  //地图搜索框
  handleStartSelect(event: any) {
      console.log(event, "起点经纬度 [lng,lat]");
      
      if (this.marker) {
        this.map.remove(this.marker);
        this.marker = null;
      }
      
      this.startSeacrh = [];
      this.stratInfo = {};
      this.startSeacrh = [event.poi.location.lng, event.poi.location.lat];
      this.stratInfo = {
        district: event.poi.district,
        address: event.poi.address,
        name: event.poi.name,
      };
      this.insureAdress = event.poi.district + event.poi.address + event.poi.name;
      this.insureAdress2 = "[" + event.poi.location.lng + "," + event.poi.location.lat + "]";
      // marker添加地图上
       this.marker = new this.amap.Marker({
         position: this.startSeacrh, //位置
       });
       this.map.add(this.marker); //添加到地图
      
      // 传入经纬度，设置地图中心点
      this.map.setCenter(this.startSeacrh);
      //this.positionPicker.start(this.startSeacrh);  //启动这句控制 地图出现一个maker点，可以移动
      // this.positionPicker.stop();   //取消移动的maker
      this.map.setZoom(15);
 
      // 添加自定义弹窗
      // this.addDialog(this.insureAdress);
  }
  gaodeClose(){
    this.gaode_dis=false
  }
  //确定
  gaodeTrue(){
    if(this.thisPosition.lng==''||this.thisPosition.lat==''){
      this.$message.error('请标点')
      return false
    }else{
      this.gaode_dis=false
    }
    
  }
    
}
